import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useQuote } from '../../context/Quote/QuoteContext';
import LinkButton from '../generic/LinkButton';
import Section from '../generic/Section';
import styles from './index.module.scss';

export default function GetInTouchFooter() {
  let {
    site: {
      siteMetadata: { orderPage, contactPage },
    },
  } = useStaticQuery(sitePhrasesQuery);
  const { quote } = useQuote();

  const linkTo = gotQuoteContent(quote) ? orderPage.url : contactPage.url;
  const linkLabel = gotQuoteContent(quote) ? `View ${orderPage.title}` : `Contact us here`;
  return (
    <Section className={styles.getInTouch}>
      <h3 className={styles.header}>
        Interested in this setup? <br />
        Get in touch.
      </h3>
      <p>
        <LinkButton
          className={styles.linkButton}
          to={linkTo}
          label={linkLabel}
          type="Primary"
          action="Link"
          color="DarkGreen"
          icon="quote"
        />
      </p>
    </Section>
  );
}

function gotQuoteContent(quote) {
  return Boolean(quote.length);
}

const sitePhrasesQuery = graphql`
  query {
    site {
      siteMetadata {
        orderPage {
          title
          url
        }
        contactPage {
          title
          url
        }
      }
    }
  }
`;
