import Gallery from '../Gallery';
import React from 'react';
import RichText from '../generic/RichText';
import { ContentBlock } from 'components/generic/ContentBlock';
import { Column, Row } from 'components/generic/Grid';
import { resolveBackgroundColors } from 'styles';
import { ContentfulRoomExperience } from 'types';

type RoomExperienceProps = { roomExperience: ContentfulRoomExperience };

export default function RoomExperience({ roomExperience }: RoomExperienceProps) {
  const { heading, subHeading, subsections } = roomExperience;

  const backgroundColor = resolveBackgroundColors(undefined);
  const mobilePaddingY: 'medium' | undefined = backgroundColor ? 'medium' : undefined;
  const desktopPaddingY: 'large' | undefined = backgroundColor ? 'large' : undefined;

  if (!subsections) return null;
  return (
    <ContentBlock mobilePaddingY={mobilePaddingY} desktopPaddingY={desktopPaddingY} backgroundColor={backgroundColor}>
      <Row mobileRowGap="medium" desktopRowGap="large">
        <Column tablet="12/12" desktop="4/12">
          <h3 className={heading}>{heading}</h3>
          <RichText className={subHeading}>{subHeading}</RichText>
        </Column>
        <Column tablet="12/12" desktop="8/12">
          <Gallery
            slides={subsections.map(subsection => ({
              ...subsection,
              image: { ...subsection.image, ...subsection.image.image },
            }))}
            className={'galleryColumn'}
            animation="fadeAnimation"
            showFullscreenControls={false}
            showDownloadControls={false}
            showArrowControls={false}
          />
        </Column>
      </Row>
    </ContentBlock>
  );
}
