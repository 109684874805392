import Icon, { IconTypes } from '../../generic/Icon';

import Image from '../../generic/Image';
import React from 'react';
import Section from '../../generic/Section';
import styles from './index.module.scss';

export default function RoomDownloads({ assets }) {
  return (
    <Section className={styles.roomDownloads} innerClassName={styles.inner} size="max">
      <h4 className={styles.heading}>Downloads</h4>
      <div className={styles.grid}>
        {assets.map((asset, index) => {
          if (!asset.image) return null;
          return (
            <div key={index} className={styles.asset}>
              <Image className={styles.image} image={asset.image.gatsbyImageData} alt={asset.image.description} />
              <div className={styles.assetHeading}>{asset.heading}</div>
              <button
                className={styles.button}
                onClick={() => {
                  let image = asset.image;
                  fetch(image.file.url)
                    .then(response => {
                      return response.blob();
                    })
                    .then(function (blob) {
                      window.saveAs(blob, 'Cisco Project Workplace - ' + image.title);
                    });
                }}
              >
                <Icon className={styles.icon} IconType={IconTypes.download} label={'Download image'} />
              </button>
            </div>
          );
        })}
      </div>
    </Section>
  );
}
