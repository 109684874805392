import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { mergeClassNames, useClientRect } from '../../../utils';
import styles from './index.module.scss';

export default function BigToggle({
  isDefault = true,
  onClick = null,
  toggleCaptions = { left: '', right: '' },
  title = null,
  className = null,
  style = null,
}) {
  const [leftButtonMeasureRef, leftButtonRect] = useClientRect();
  const [rightButtonMeasureRef, rightButtonRect] = useClientRect();

  const [{ transform: activeBackgroundTransform }] = useSpring(
    () => ({
      from: {
        transform: activeBackgroundTranslate(
          !isDefault,
          findLeftActiveBackgroundPos(leftButtonRect, rightButtonRect),
          leftButtonRect
        ),
      },
      transform: activeBackgroundTranslate(
        isDefault,
        findLeftActiveBackgroundPos(leftButtonRect, rightButtonRect),
        leftButtonRect
      ),
    }),
    [isDefault, leftButtonRect, rightButtonRect]
  );

  // left toggle button transform
  const leftSprings = useSpring({
    transform: `translate(${isDefault ? 0 : leftButtonRect.width}px`,
    opacity: isDefault ? 1 : 0,
  });

  // right toggle button transform
  const rightSprings = useSpring({
    transform: `translateX(${isDefault ? -rightButtonRect.width : 0}px)`,
    opacity: !isDefault ? 1 : 0,
  });

  return (
    <button className={mergeClassNames([styles.bigToggle, className])} style={style} onClick={onClick} title={title}>
      <div className={styles.toggleInner}>
        {/* left inactive text */}
        <span className={mergeClassNames(styles.toggleItem)} ref={leftButtonMeasureRef}>
          {toggleCaptions.left}
        </span>
        {/* right inactive text */}
        <span className={mergeClassNames(styles.toggleItem)} ref={rightButtonMeasureRef}>
          {toggleCaptions.right}
        </span>

        {/* active background */}
        <animated.span
          className={mergeClassNames([
            styles.toggleItem,
            styles.highlightItem,
            styles.toggleItemBackground,
            styles.left,
          ])}
          style={{
            transform: activeBackgroundTransform,
            width: leftButtonRect.width > rightButtonRect.width ? leftButtonRect.width : rightButtonRect.width,
            left: `${findLeftActiveBackgroundPos(leftButtonRect, rightButtonRect)}px`,
          }}
        />

        {/* left active label */}
        <animated.span
          className={mergeClassNames([styles.toggleItem, styles.highlightItem, styles.highlightLabel, styles.left])}
          style={leftSprings}
        >
          {toggleCaptions.left}
        </animated.span>
        {/* right active label */}
        <animated.span
          className={mergeClassNames([styles.toggleItem, styles.highlightItem, styles.highlightLabel, styles.right])}
          style={rightSprings}
        >
          {toggleCaptions.right}
        </animated.span>
      </div>
    </button>
  );
}

function findLeftActiveBackgroundPos(leftButtonRect, rightButtonRect) {
  const leftRightButtonDifference = leftButtonRect.width - rightButtonRect.width;
  return leftRightButtonDifference < 0 ? leftRightButtonDifference : 0;
}

function activeBackgroundTranslate(isDefault, leftActiveBackgroundPos, leftButtonRect) {
  let rightButtonTranslate = leftButtonRect.width - leftActiveBackgroundPos;
  let translate = `translate(${isDefault ? 0 : rightButtonTranslate}px)`;
  return translate;
}
