import React, { useCallback } from 'react';
import { PrimaryButton } from 'components/generic/Button';
import { useQuote } from 'context/Quote/QuoteContext';
import { Product } from 'context/Quote/typedefs';

interface BuyRoomButtonProps {
  products: Product[];
  className?: string;
}

export default function BuyRoomButton({ products, className }: BuyRoomButtonProps) {
  const { dispatchQuote } = useQuote();

  const clickhandler = useCallback(() => {
    dispatchQuote({
      type: 'add',
      payload: { item: products, label: `'Buy this room' clicked` },
    });
  }, [products, dispatchQuote]);

  return (
    <PrimaryButton
      clickHandler={clickhandler}
      title={getInventoryAsString(products)}
      disabled={!products}
      className={className}
    >
      Buy this room
    </PrimaryButton>
  );
}

function getInventoryAsString(items: Product[]) {
  items = items ? items : [];
  return items.map(product => `Add the ${product.shortHeading || product.heading} to your quote`).join(`\n`);
}
