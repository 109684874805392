import React, { useState, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useTrackCustomEvent } from '../../../utils/useTrackCustomEvent';
import BigToggle from '../../generic/BigToggle';
import { mergeClassNames } from '../../../utils';
import styles from './index.module.scss';

export default function WorkspaceToggle({
  // eslint-disable-next-line no-unused-vars
  path = '', // leave `path` in, to prevent type errors in Components calling this
  page = '',
  pages = [],
  className = null,
  style = null,
}) {
  let trackCustomEvent = useTrackCustomEvent();
  const navigate = useNavigate();

  const [left = {}, right = {}] = pages;
  const isOnLeft = left.slug === page;
  const [isDefault, setIsDefault] = useState(isOnLeft);

  const otherPage = pages.find(({ slug }) => slug !== page)?.link;

  const clickHandler = useCallback(() => {
    trackCustomEvent({
      category: `Workspace toggle`,
      action: `Workspace page toggle clicked`,
      label: `from: ${window.location.pathname} - navigate to ${otherPage}`,
    });
    navigate(otherPage, { state: { workspaceToggle: true } });
    setIsDefault(state => !state);
  }, [navigate, otherPage, trackCustomEvent]);

  if (pages.length > 1) {
    return (
      <div className={styles.workspaceToggle}>
        <BigToggle
          toggleCaptions={{ left: left.title, right: right.title }}
          className={mergeClassNames([styles.workspaceTogglePosition, className])}
          style={style}
          isDefault={isDefault}
          onClick={clickHandler}
          title={`current: ${page}, navigateTo: ${otherPage}`}
        />
      </div>
    );
  } else {
    return null;
  }
}
