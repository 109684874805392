import React, { forwardRef } from 'react';
import Section from '../generic/Section';
import { InventoryType, Product } from 'context/Quote/typedefs';
import ProductListContent from './ProductListContent';
import PricingDisclaimer from './PricingDisclaimer';
import { Row, Column } from 'components/generic/Grid';
import styled from 'styled-components';
import { universalMargin } from 'styles/variables';
import { gridPaddings } from 'styles/grid';

type ProductListProps = {
  products: Array<Product>;
  disclaimer?: boolean;
};

const ProductList = forwardRef<HTMLElement, ProductListProps>(function ProductList(
  { products, disclaimer = true }: ProductListProps,
  ref
) {
  if (!products) {
    return null;
  }

  return (
    <StyledSection innerClassName="productList" size="max" ref={ref}>
      {products.reduce(mergeIdenticalProducts, []).map(product => {
        return <ProductListContent key={product.id} item={product} />;
      })}
      {disclaimer && (
        <Row>
          <Column desktop="5/12">
            <StyledPricingDisclaimer />
          </Column>
        </Row>
      )}
    </StyledSection>
  );
});

export default ProductList;

function mergeIdenticalProducts(allProducts: InventoryType[], product: InventoryType) {
  let existing = allProducts.find(existingProduct => {
    return existingProduct.id === product.id;
  });
  if (!existing) allProducts.push(product);
  return allProducts;
}

const StyledSection = styled(Section)`
  ${gridPaddings}
  .productList {
    padding-bottom: ${universalMargin * 16}px;
  }
`;

const StyledPricingDisclaimer = styled(PricingDisclaimer)`
  padding-bottom: ${universalMargin * 4}px;
`;
