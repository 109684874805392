import React, { createContext, useContext, useMemo, useState, useCallback } from 'react';

export const TocContext = createContext(null);

export function TocProvider({ children }) {
  let [sections, setSections] = useState(new Map());

  let registerSection = useCallback(
    section => {
      setSections(sections => {
        return new Map(sections.set(section.id, section));
      });
      return () => {
        setSections(sections => {
          sections.delete(section.id);
          return new Map(sections);
        });
      };
    },
    [setSections]
  );

  let contextValue = useMemo(() => {
    let sectionsValues = Array.from(sections.values());

    return { sections, sectionsValues, registerSection };
  }, [sections, registerSection]);

  return <TocContext.Provider value={contextValue}>{children}</TocContext.Provider>;
}

export function useSections() {
  return useContext(TocContext);
}
