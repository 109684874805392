import React from 'react';
import styled from 'styled-components';
import Breadcrumbs, {
  BreadcrumbGoToAnchor,
  BreadcrumbLink,
  LocationBreadcrumbs,
} from '../../../SecondaryNavigation/Breadcrumbs';
import { useSections } from '../TocContext';

type TocProps = { heading: string; workspaceLink: string };

export function Toc({ heading, workspaceLink }: TocProps) {
  const { sectionsValues } = useSections() as unknown as {
    sectionsValues: Array<{ id: string; title: string; node: { current: HTMLElement } }>;
  };

  return (
    <StyledBreadcrumbs>
      <LocationBreadcrumbs>
        <BreadcrumbLink to="/workspaces">Workspaces</BreadcrumbLink>
        <BreadcrumbLink to={workspaceLink}>{heading}</BreadcrumbLink>
      </LocationBreadcrumbs>
      {sectionsValues.map(({ title, id }) => {
        return (
          <BreadcrumbGoToAnchor key={id} id={id}>
            {title}
          </BreadcrumbGoToAnchor>
        );
      })}
    </StyledBreadcrumbs>
  );
}

const StyledBreadcrumbs = styled(Breadcrumbs)`
  z-index: 200;
`;
