import {
  Fixed,
  HeightElem,
  LocalSectionProvider,
  ScrollDistanceElem,
  TransitionFadeOnScroll,
} from '../ScrollProgression';
import React, { useRef } from 'react';
import { mergeClassNames, useInCloseProximityToViewport } from '../../../utils';

import BuyRoomButton from '../BuyRoomButton';
import styles from './index.module.scss';

/** Button section to contain "Buy this room" and the workspace product switch */
export default function ButtonSection({
  children,
  placeholderImage,
  heightMultiplier = 4,
  altPosition,
  products = [],
}) {
  let ref = useRef(null);
  let outOfViewport = !useInCloseProximityToViewport(ref);

  let title = 'Button section';
  let id = 'button-section';

  let scrollDistance = Array.from(Array(heightMultiplier).keys(), i => (
    <ScrollDistanceElem key={i} image={placeholderImage} isFullHeight />
  ));

  return (
    <LocalSectionProvider title={title} id={id} sectionRef={ref}>
      <TransitionFadeOnScroll className={styles.buttonSection}>
        <Fixed
          deactivateFixed={outOfViewport}
          className={styles.fixedContainer}
          overlay={
            <>
              {children}

              <BuyRoomButton
                className={mergeClassNames(
                  styles.buyThisRoomButtonSectionPosition,
                  altPosition ? styles.altPos : styles.defaultPos,
                  styles.desktop
                )}
                products={products}
              />
            </>
          }
          constrainOverlay
        >
          <HeightElem
            aspectRatio={
              placeholderImage?.file.details.image.width / placeholderImage?.file.details.image.height || 1.777
            }
          />
          <div className={styles.handheldContainer}>
            <BuyRoomButton
              className={mergeClassNames(
                styles.buyThisRoomButtonSectionPosition,
                altPosition ? styles.altPos : styles.defaultPos,
                styles.handheld
              )}
              products={products}
            />
          </div>
        </Fixed>
      </TransitionFadeOnScroll>
      {/* the scroll distance 👇 */}
      <div className={styles.outerScrollerWrapper} aria-hidden>
        <div className={styles.innerScrollerWrapper}>
          <div ref={ref}>{scrollDistance}</div>
        </div>
      </div>
    </LocalSectionProvider>
  );
}
