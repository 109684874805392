import Image from 'components/generic/Image';
import React, { useRef } from 'react';
import { Fixed } from './ScrollProgression';
import ScrollToSeeMore from 'components/generic/ScrollToSeeMore';
import { useInCloseProximityToViewport } from 'utils/useIntersectionObserver';
import { useSpring, animated } from '@react-spring/web';
import { Image as ImageType } from 'types';
import styled, { css } from 'styled-components';
import { breadcrumbHeight, h2Heading, largeBody, mediaQueries, mediumBody, universalMargin } from 'styles';

type PageHeadingProps = {
  heading: string;
  description: string;
  image: ImageType;
};

/**
 * PageHeading is a component that superimposes a semitransparent curtain-like element
 */
export default function PageHeading({ heading, description, image }: PageHeadingProps) {
  let { intersectionRef, outOfViewport } = useOutOfViewport();
  let springProps = useSpring({
    from: {
      opacity: 0,
      transform: `translateY(100%)`,
    },
    opacity: 1,
    transform: `translateY(0)`,
    delay: 500,
  });

  return (
    <>
      <StyledFixed deactivateFixed={outOfViewport}>
        <Image image={image.gatsbyImageData} alt={image.description} />
      </StyledFixed>

      <PageHeadingCurtain ref={intersectionRef}>
        <animated.div style={springProps} className="heading">
          <h2>{heading}</h2>
          <p>{description}</p>
        </animated.div>
        <Overlay>
          <ScrollToSeeMoreWrapper>
            <StyledScrollToSeeMore />
          </ScrollToSeeMoreWrapper>
        </Overlay>
      </PageHeadingCurtain>
    </>
  );
}

function useOutOfViewport() {
  let intersectionRef = useRef<HTMLDivElement>(null);
  let outOfViewport = !useInCloseProximityToViewport(intersectionRef);
  return { intersectionRef, outOfViewport };
}

const StyledFixed = styled(Fixed)`
  overflow: hidden;
`;

const PageHeadingCurtain = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - var(--headerHeight));
  ${mediaQueries.large(css`
    height: calc(100vh - var(--headerHeight) - ${breadcrumbHeight}px);
  `)}
  top: 0;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.7);

  .heading {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 720px;
    padding-top: 15vh;
    @media (orientation: portrait) {
      ${mediaQueries.small(css`
        padding-top: 5vh;
      `)}
    }

    padding-left: 2rem;
    padding-right: 2rem;

    * {
      margin: 0 auto;
    }

    > h2 {
      ${h2Heading}
      text-align: center;
      margin-bottom: 10px;
      ${mediaQueries.small(css`
        margin-bottom: 20px;
      `)}
      ${mediaQueries.large(css`
        margin-bottom: 14px;
      `)}
    }

    > p {
      ${mediumBody}
      text-align: center;

      max-width: 260px;
      ${mediaQueries.small(css`
        max-width: 380px;
        ${largeBody}
      `)}
      ${mediaQueries.small(css`
        max-width: 520px;
      `)}
    }
  }

  .curtain-top-extension {
    position: absolute;
    top: -100vh;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: inherit;
  }
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ScrollToSeeMoreWrapper = styled.div`
  display: flex;
  height: calc(100vh - var(--headerHeight));
  ${mediaQueries.large(css`
    height: calc(100vh - var(--headerHeight) - ${breadcrumbHeight}px);
  `)}
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  pointer-events: none;
`;

const StyledScrollToSeeMore = styled(ScrollToSeeMore)`
  position: absolute;
  bottom: ${universalMargin * 2}px;
  ${mediaQueries.small(css`
    bottom: ${universalMargin * 7};
  `)}
`;
