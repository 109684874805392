import { Router, useMatch } from '@reach/router';
import { ContentfulWorkspaceSubCategory } from 'types';
import ButtonSection from '../components/Workspace/ButtonSection';
import Chapter from '../components/Workspace/Chapter';
import Features from '../components/Workspace/Features';
import GetInTouchFooter from '../components/GetInTouchFooter';
import Head from '../components/Head';
import HotspotHero from '../components/Workspace/HotspotHero';
import PageHeading from '../components/Workspace/PageHeading';
import ProductList from '../components/ProductList';
import React from 'react';
import RoomDownloads from '../components/Workspace/RoomDownloads';
import RoomExperience from '../components/Workspace/RoomExperience';
import WorkspaceToggle from '../components/Workspace/WorkspaceToggle';
import { graphql, PageProps } from 'gatsby';
import { Toc, TocProvider } from 'components/Workspace/TableOfContents';
import styled from 'styled-components';

type WorkspacePageData = { contentfulWorkspaceSubCategory: ContentfulWorkspaceSubCategory };
type WorkspaceContext = { id: string; matchPathPattern: string; slug: string };

export default function Workspace({
  data,
  pageContext,
  path,
  ...props
}: PageProps<WorkspacePageData, WorkspaceContext>) {
  const matchedPath = useMatch(pageContext.matchPathPattern);
  const pageMatch = matchedPath !== null && '*' in matchedPath ? matchedPath['*'] : '';
  const {
    products,
    featuresHeading,
    features,
    hotspotHero,
    description,
    heading,
    mainProductTitle,
    roomExperience,
    pageVariantsMeta,
    infoboxAltPosition,
  } = assertData(data, pageMatch);

  const basepath = path.replace(/\/\*/, '');
  const defaultImage = hotspotHero?.image;

  const variantLinks = pageVariantsMeta.map(({ heading, shortHeading, slug }) => {
    const link = `${basepath}/${slug}`;
    return { title: shortHeading ? shortHeading : heading, slug, link };
  });
  const [defaultWorkspaceLink] = variantLinks;

  return (
    <TocProvider>
      <Head location={props.location} title={heading} description={description} image={hotspotHero?.image?.file.url} />

      <Toc heading={heading} workspaceLink={defaultWorkspaceLink.link} />
      {Boolean(products && defaultImage) && (
        <ButtonSection
          placeholderImage={defaultImage}
          heightMultiplier={2 + (features?.length || 2)} // ButtonSection scroll distance
          altPosition={infoboxAltPosition}
          products={products}
        >
          <Router basepath={basepath} primary={false}>
            <WorkspaceToggle pages={variantLinks} path="/" page={pageContext.slug} />
            <WorkspaceToggle pages={variantLinks} path={`/:page`} />
          </Router>
        </ButtonSection>
      )}

      {/* PageHeading section */}
      {defaultImage ? <PageHeading heading={heading} description={description} image={defaultImage} /> : null}

      {/* HotspotHero section */}
      {hotspotHero ? (
        <Chapter title="The room">
          <HotspotHero hotspotHero={hotspotHero} infocardAlternatePos={infoboxAltPosition} />
        </Chapter>
      ) : null}

      {/* Features section / Workspace' Ways to collaborate section  */}
      {Boolean(features) && (
        <Chapter title="Ways to collaborate">
          <Features
            defaultImage={hotspotHero?.image}
            featuresHeading={featuresHeading}
            features={features}
            productTitle={mainProductTitle}
            infocardAlternatePos={infoboxAltPosition}
          />
        </Chapter>
      )}

      {/* WorkspaceProducts section */}
      {Boolean(products) && (
        <WhiteBackground as={Chapter} title="Products">
          {/* we simulate the old product bundle 👇 to leverage the 'Bundled products' in the <ProductList>.<ProductListContent> */}
          <ProductList products={products} />
        </WhiteBackground>
      )}

      {roomExperience ? (
        <WhiteBackground as={Chapter} title="Room Experience" Tag="div">
          <RoomExperience roomExperience={roomExperience} />
        </WhiteBackground>
      ) : null}

      {Boolean(features) && <RoomDownloads assets={[data.contentfulWorkspaceSubCategory, ...features]} />}

      <WhiteBackground as={Chapter} title="Get in touch">
        <GetInTouchFooter />
      </WhiteBackground>
    </TocProvider>
  );
}

const WhiteBackground = styled.div`
  background-color: white;
  position: relative;
  z-index: 10;
`;

function assertData(data: WorkspacePageData, pageSlug: string) {
  const { workspaces, heading, description: descriptionMeta } = data.contentfulWorkspaceSubCategory;
  const { description = '' } = descriptionMeta ? descriptionMeta : {};
  const {
    products = [],
    featuresHeading = '',
    features = [],
    featuresInfoboxAlternatePosition,
    hotspotHero,
    roomExperience,
  } = data.contentfulWorkspaceSubCategory.workspaces.find(page => page.slug === pageSlug) || {};

  const pageVariantsMeta = workspaces
    ? workspaces.map(({ slug, heading, shortHeading }) => ({ slug, heading, shortHeading }))
    : [];
  const [mainBundle] = products && products?.length ? products : [];
  const mainProductTitle = mainBundle?.shortHeading || mainBundle?.heading || '';

  const infoboxAltPosition = featuresInfoboxAlternatePosition ? featuresInfoboxAlternatePosition : false;

  return {
    products,
    featuresHeading,
    features,
    infoboxAltPosition,
    hotspotHero,
    description,
    heading,
    mainBundle,
    mainProductTitle,
    roomExperience,
    pageVariantsMeta,
  };
}

export const query = graphql`
  query ($id: String!) {
    contentfulWorkspaceSubCategory(id: { eq: $id }) {
      ...WorkspaceSubCategory
    }
  }
`;

export const WorkspaceSubCategory = graphql`
  fragment WorkspaceSubCategory on ContentfulWorkspaceSubCategory {
    __typename
    id
    heading
    workspaceCategory: workspace_category {
      id
      heading
      slug
    }
    heading
    slug
    image {
      ...Image
    }
    numberOfSeats
    physicalSizeM2
    description {
      description
    }
    workspaces {
      ...Workspace
    }
    gridSize
  }
`;

export const workspaceCardFragment = graphql`
  fragment WorkspaceGridCard on ContentfulWorkspace {
    __typename
    id
    heading
    shortHeading
    slug
    images {
      ...PwpImage
    }
    workspaceSubCategory: workspace_sub_category {
      __typename
      heading
      slug
      workspaceCategory: workspace_category {
        __typename
        slug
      }
      image {
        ...GridImage
      }
      numberOfSeats
      physicalSizeM2
      description {
        description
      }
      gridSize
    }
    hotspotHero {
      id
      image {
        ...GridImage
      }
    }
  }
`;

export const workspaceQuery = graphql`
  fragment Workspace on ContentfulWorkspace {
    id
    heading
    shortHeading
    slug
    images {
      ...PwpImageFullWidth
    }
    hotspotHero {
      ...HotspotHero
    }
    products {
      ...Product
    }
    featuresHeading
    features {
      ...WorkspaceFeature
    }
    featuresInfoboxAlternatePosition
    roomExperience {
      ...WorkspaceRoomExperience
    }
  }
`;

export const hotspotHero = graphql`
  fragment HotspotHero on ContentfulHotspotHero {
    __typename
    id
    image {
      ...ImageFullWidth
    }
    hotspots {
      # heading # unused
      description {
        description
      }
      type
      position {
        x
        y
      }
      image {
        ...ImageFullWidth
      }
      product {
        ...Product
      }
    }
  }
`;

export const feature = graphql`
  fragment WorkspaceFeature on ContentfulFeature {
    __typename
    id
    heading
    description {
      description
    }
    image {
      ...ImageFullWidth
    }
    icon {
      ...Image
    }
    position
  }
`;

export const roomExperienceQuery = graphql`
  fragment WorkspaceRoomExperience on ContentfulRoomExperience {
    __typename
    id
    heading
    subHeading
    subsections {
      __typename
      heading
      shortHeading
      body {
        raw
        references {
          ...RichTextAssetBase
          ...Image
        }
      }
      icon {
        ...Image
        ... on ContentfulAsset {
          svg {
            content # gatsby-transformer-inline-svg
          }
          file {
            contentType
          }
        }
      }
      image {
        ...PwpImage
      }
    }
  }
`;
