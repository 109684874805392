import React, { useEffect, useRef } from 'react';
import { toKebabCase } from '../../../utils';
import { LocalSectionProvider } from '../ScrollProgression';
import { useSections } from '../TableOfContents';

export default function Chapter({ title, children, className = '', style = {}, Tag = 'section' }) {
  let ref = useRef(null);

  let id = toKebabCase(title);
  let { registerSection } = useSections();

  useEffect(() => {
    let unregister = registerSection({ id, title, node: ref });
    return () => unregister();
  }, [id, title, registerSection]);

  return (
    <LocalSectionProvider id={id} title={title} sectionRef={ref}>
      <Tag className={className} style={style} id={id} ref={ref}>
        {children}
      </Tag>
    </LocalSectionProvider>
  );
}
